import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, ListingCard, SEO, Header, SliceZone, Wrapper } from '../components';
import website from '../../config/website';

const Page = ({ data: { prismicCategory, posts, totalCount }, location }) => {
  const { data } = prismicCategory;

  return (
    <Layout location={location}>
      <SEO
        title={`${data.name} | ${website.title}`}
        pathname={location.pathname}
        desc={data.desc}
        node={prismicCategory}
        // article
      />
      <Header light />
      <div>
        <SliceZone allSlices={data.body} posts={posts} />
        <Wrapper id={website.skipNavId}>
          {/*<div>*/}
          {/*  {posts.totalCount} {posts.totalCount === 1 ? 'publication' : 'publications'}{' '}*/}
          {/*  {posts.totalCount === 1 ? 'est' : 'sont'} dans la catégorie "{data.name}"*/}
          {/*</div>*/}
          <ListingCard posts={posts.edges} />
        </Wrapper>
      </div>
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  data: PropTypes.shape({
    prismicCategory: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query CategoryPage($categorie: String!) {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: { data: { categorie: { document: { elemMatch: { uid: { eq: $categorie } } } } }, uid: { ne: "dummy" } }
    ) {
      totalCount
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicCategory(uid: { eq: $categorie }) {
      uid
      data {
        name
        desc
        color
        body {
          ... on PrismicCategoryBodyHero {
            slice_type
            id
            primary {
              maintitle {
                html
                text
              }
              subtitle {
                html
                text
              }
              introduction {
                html
                text
              }
              bgimg {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1960, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              illustration {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              bgcolor
              orientation
              theme
            }
          }
        }
      }
    }
  }
`;
